$(window).on('load', function () {
  AOS.refresh();
});

$(function () {
  AOS.init({
    easing: 'ease-in-out',
    mirror: false,
    duration:600,
    once: true
  });
});

$(document).ready(function() { 
    //Mobile menu
    $(".MenuBar").click(function(e){
      e.preventDefault();
      $("#header .NavContainer").toggleClass("Active_Nav");
    });

    //Check Screen size
    let checkScrennSize = () => {
      if (window.matchMedia('(max-width: 991px)').matches) {
          // $(".haveSubmenu>a").append('<span><img src="assets/img/chevron-down-white.svg"></span>');
          $(".haveSubmenu>a").append('<span></span>');
      }
      $(".haveSubmenu").find('a>span').click(function(e){
      // $(".haveSubmenu>a>span").click(function(e){
        // e.stopPropagation();
        e.preventDefault();
        $(this).parents('a').parent().toggleClass("Active_MobileDropdown");
      });
    }
    checkScrennSize();

    // Fixed Header on scroll for Desktop
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() { 
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        document.querySelector("header").classList.add("stickyHeader");
      } else {
        document.querySelector("header").classList.remove("stickyHeader");
      }
    };

    $('.filterNav ul>li:first-child button').addClass('is-checked');

    $(".socialShareWrap > a.shareIcon").click(function(e){
      e.preventDefault();
      e.stopPropagation();
       // $('.socialShareWrap .socialShare').removeClass("show");
       $(this).parent().find('.socialShare').toggleClass("show");
    }); 
   

/// external js: isotope.pkgd.js

$(window).scroll(function(){ 
    if ($(this).scrollTop() > 200) { 
        $('#scrollTop').fadeIn(); 
    } else { 
        $('#scrollTop').fadeOut(); 
    } 
}); 
$('#scrollTop').click(function(){ 
    $("html, body").animate({ scrollTop: 0 }, 600); 
    return false; 
});

// init Isotope
var $grid = $('.grid').isotope({
  itemSelector: '.element-item',
  layoutMode: 'fitRows'
});
// filter functions
var filterFns = {
  // show if number is greater than 50
  numberGreaterThan50: function() {
    var number = $(this).find('.number').text();
    return parseInt( number, 10 ) > 50;
  },
  // show if name ends with -ium
  ium: function() {
    var name = $(this).find('.name').text();
    return name.match( /ium$/ );
  }
};
// bind filter button click
$('.filters-button-group').on( 'click', 'button', function() {
  var filterValue = $( this ).attr('data-filter');
  // use filterFn if matches value
  filterValue = filterFns[ filterValue ] || filterValue;
  $grid.isotope({ filter: filterValue });
});
// change is-checked class on buttons
$('.button-group').each( function( i, buttonGroup ) {
  var $buttonGroup = $( buttonGroup );
  $buttonGroup.on( 'click', 'button', function() {
    $buttonGroup.find('.is-checked').removeClass('is-checked');
    $( this ).addClass('is-checked');
  });
});

// Magnific Zoom Gallery
$('.zoom-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    closeOnContentClick: false,
    closeBtnInside: false,
    mainClass: 'mfp-with-zoom mfp-img-mobile',
    image: {
      verticalFit: true,
      titleSrc: function(item) {
        return item.el.attr('title') + ' &middot; <a class="image-source-link" href="'+item.el.attr('data-source')+'" target="_blank">image source</a>';
      }
    },
    gallery: {
      enabled: true
    },
    zoom: {
      enabled: true,
      duration: 300,
      opener: function(element) {
        return element.find('img');
      }
    }
    
  });

// Load More Script
$(".infrastructure_gallery .ImgWrap").slice(0, 7).show(); // select the first ten
  $("#loadMore").click(function(e){ // click event for load more
      e.preventDefault();
      $(".infrastructure_gallery .ImgWrap:hidden").slice(0, 3).fadeIn(200); // select next 10 hidden divs and show them
      if($(".infrastructure_gallery .ImgWrap:hidden").length == 0){ // check if any hidden divs still exist
          $(this).hide();
      }
  });

  // HomePage Banner Slider
  $('.HomeBanner').slick({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        fade:true,
        pauseOnHover:true,
        arrows: false
    });

    // HomePage Products Slider
  $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    infinite: true,
    asNavFor: '.slider-nav'
  });


  $('.slider-nav').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    arrows: false,
    centerMode: false,
    focusOnSelect: true,
    infinite: true,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }]
  }); 


  $('.otherProductSlider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    autoplay:true,
    autoplaySpeed: 2000,
    arrows: true,
    infinite: true,
    focusOnSelect: false,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  }); 

  
  
// Document Ready End

});

